import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AutoFixHighRoundedIcon from "@mui/icons-material/AutoFixHighRounded";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import axios from "axios";

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: "Adaptable performance",
    description:
      "Our product effortlessly adjusts to your needs, boosting efficiency and simplifying your tasks.",
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: "Built to last",
    description:
      "Experience unmatched durability that goes above and beyond with lasting investment.",
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: "Great user experience",
    description:
      "Integrate our product into your routine with an intuitive and easy-to-use interface.",
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: "Innovative functionality",
    description:
      "Stay ahead with features that set new standards, addressing your evolving needs better than the rest.",
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: "Reliable support",
    description:
      "Count on our responsive customer support, offering assistance that goes beyond the purchase.",
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: "Precision in every detail",
    description:
      "Enjoy a meticulously crafted product where small touches make a significant impact on your overall experience.",
  },
];

export default function Services() {
  const [banners, setBanners] = React.useState([]);
  const fetchMoreData = () => {
    axios
      .get(`https://backend-janta-rides.vercel.app/api/banners/`)
      .then((res) => {
        setBanners(res.data);
      })
      .catch((err) => console.log(err));
  };
  React.useEffect(() => {
    if (banners.length == 0) {
      fetchMoreData();
    }
  }, []);

  const cardImages = banners
    ?.filter((item) => item.category.startsWith("card")) // Filters categories like "card1", "card2", etc.
    ?.map((item) => item.imagePath);
  // console.log("cardImages", cardImages);
  return (
    <Box
      id="Services"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        backgroundColor: "#FBFCFF",
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            Services
          </Typography>
          {/* <Typography variant="body1" sx={{ color: "grey.400" }}>
          Discover why <span className="font-bold text-black">Janata Rides</span> leads the way in mobility services: seamless adaptability to customer needs, reliable and efficient vehicles, user-friendly booking experience, and cutting-edge innovation. Benefit from unparalleled customer support and a commitment to excellence in every ride.
          </Typography> */}
          <Typography variant="body1" sx={{ color: "grey.600" }}>
  Discover why{" "}
  <Typography
    component="span"
    variant="body1"
    sx={{ fontWeight: "bold", color: "text.primary" }}
  >
    Janata Rides
  </Typography>{" "}
  leads the way in mobility services: seamless adaptability to customer needs, reliable and efficient vehicles, user-friendly booking experience, and cutting-edge innovation. Benefit from unparalleled customer support and a commitment to excellence in every ride.
</Typography>

        </Box>
        <Grid container spacing={2} gap={1}>
          {cardImages?.map((item, index) => (
            <Grid
              sx={{ my: 2 }}
              size={{ xs: 12, sm: 6, md: 4 }}
              key={index + item + "01"}
            >
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: "inherit",
                  // p: 1,
                  height: "100%",
                  borderColor: "hsla(220, 25%, 25%, 0.3)",
                  // backgroundColor: "grey.800",
                }}
              >
                <img src={item} alt={`Card + ${index}`} />
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

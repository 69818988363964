import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  IconButton,
  Chip,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import VerifiedIcon from "@mui/icons-material/Verified";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PhoneIcon from "@mui/icons-material/Phone";
import { useNavigate } from "react-router-dom";
import { getAddress } from "../utils";
import OTPVerificationDialog from "./OtpVerify";
import MobileNumberLoginDialog from "./MobileNumberLoginDialog";
import EnquiryFormDialog from "./EnquiryFormDialog";
import { useSelector } from "react-redux";
import { formatMobileNumber, maskMobileNumber } from "../constants";

const BusinessCard = ({ data }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [business, setBusiness] = useState();
  const { error, isAuthenticated, user } = useSelector((state) => state.auth);
  const handleClose = () => {
    setOpen(false);
  };
  const handleSendEnquiry = (data) => {
    setOpen(true);
    setBusiness(data);
  };

  const handleWhatsAppClick = (phoneNumber) => {
    const formattedNumber = formatMobileNumber(phoneNumber);

    const message = "Hello, I would like to inquire about...";

    // Construct WhatsApp URL
    const whatsappURL = `https://wa.me/${formattedNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Open the WhatsApp URL in a new tab
    window.open(whatsappURL, "_blank");
  };

  const handleCallClick = () => {
    const mobileNumber = data?.contactDetails?.mobileNumber || "";

    if (mobileNumber) {
      // Open the dialer with the specified number
      window.location.href = `tel:${mobileNumber}`;
    } else {
      alert("No contact number available");
    }
  };

  console.log("data?.photos",data?.photos)

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          my: 1,
          borderRadius: 2,
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: 200 },
            height: { xs: 150, md: 200 },
            borderRadius: 1,
            mr: { md: 2, xs: 0 },
            mb: { xs: 2, md: 0 },
            overflow: "hidden",
          }}
        >
          <Swiper
            style={{
              "--swiper-navigation-color": "#000",
              "--swiper-navigation-size": "20px",
            }}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
          >
            {/* {[...data?.photos,"https://jantarides-s3store.s3.ap-south-1.amazonaws.com/1738575911532-2022-12-16.jpg"]?.map((item, index) => (
              <SwiperSlide key={index + item}>
                <img
                  src={item}
                  alt={`banner-${index}`}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </SwiperSlide>
            ))} */}
            {[...data?.photos?.filter(
                (item) => !item.includes("https://car-expert-admin.s3.ap-south-1.amazonaws.com")
              ), "https://jantarides-s3store.s3.ap-south-1.amazonaws.com/photos/1737545242238-WhatsApp%20Image%202025-01-22%20at%2016.56.05.jpeg","https://jantarides-s3store.s3.ap-south-1.amazonaws.com/photos/1735646683275-WhatsApp%20Image%202024-12-12%20at%206.20.46%20PM%20%281%29.jpeg"]
              ?.map((item, index) => (
                <SwiperSlide key={index + item}>
                  <img
                    src={item}
                    alt={`banner-${index}`}
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </Box>
        {/* Right side - Content */}
        <CardContent
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {/* Title and Ratings */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: { xs: "wrap", md: "nowrap" },
              px: { xs: 1, md: 0 },
            }}
          >
            <Typography
              variant="h6"
              fontWeight="bold"
              onClick={() => navigate(`/detailspage/${data?._id}`)}
            >
              {data?.businessName}
            </Typography>
            <Chip
              label="4.8"
              icon={<ThumbUpAltIcon style={{ color: "white" }} />}
              size="small"
              sx={{
                backgroundColor: "green",
                color: "white",
                ml: 1,
                fontWeight: "bold",
              }}
            />
            <Typography variant="body2" sx={{ ml: 1, color: "gray" }}>
              234 Ratings
            </Typography>
            <VerifiedIcon sx={{ color: "blue", ml: 1 }} />
            <TrendingUpIcon sx={{ color: "orange", ml: 1 }} />
          </Box>

          {/* Location */}
          <Box
            sx={{
              px: { xs: 1, md: 0 },
              display: "flex",
              alignItems: "center",
              mb: 1,
            }}
          >
            <LocationOnIcon fontSize="small" />
            <Typography variant="body2" sx={{ ml: 0.5 }}>
              {getAddress(data?.address)}
            </Typography>
          </Box>

          {/* Services and Tags */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              mb: 1,
              px: { xs: 1, md: 0 },
            }}
          >
            <Chip label={data?.category?.name ?? "jantarides"} size="small" />
            <Chip label={data?.address?.city} size="small" />
            <Chip label="All India" size="small" />
          </Box>

          {/* Contact Buttons */}
          <Box
            sx={{
              px: { xs: 1, md: 0 },

              display: "flex",
              flexWrap: { xs: "wrap", md: "nowrap" },
              alignItems: "center",
              gap: 1,
            }}
          >
            {!isAuthenticated ? (
              <Button
                variant="contained"
                startIcon={<PhoneIcon />}
                sx={{ backgroundColor: "green", mb: { xs: 1, md: 0 } }}
                onClick={() => navigate(`#login`)}
              >
                {maskMobileNumber(data?.contactDetails?.mobileNumber)}
              </Button>
            ) : (
              <Button
                variant="contained"
                startIcon={<PhoneIcon />}
                sx={{ backgroundColor: "green", mb: { xs: 1, md: 0 } }}
                onClick={handleCallClick}
              >
                {data?.contactDetails?.mobileNumber}
              </Button>
            )}

            {!isAuthenticated ? (
              <Button
                variant="outlined"
                sx={{ mb: { xs: 1, md: 0 } }}
                onClick={() => navigate(`#login`)}
              >
                Send Enquiry
              </Button>
            ) : (
              <Button
                variant="outlined"
                sx={{ mb: { xs: 1, md: 0 } }}
                onClick={() => handleSendEnquiry(data)}
              >
                Send Enquiry
              </Button>
            )}

            {!isAuthenticated ? (
              <IconButton
                sx={{
                  border: "1px solid gray",
                  borderRadius: 2,
                  color: "green",
                }}
                onClick={() => navigate(`#login`)}
              >
                <WhatsAppIcon />
              </IconButton>
            ) : (
              <IconButton
                sx={{
                  border: "1px solid gray",
                  borderRadius: 2,
                  color: "green",
                }}
                onClick={() =>
                  handleWhatsAppClick(data?.contactDetails?.mobileNumber)
                }
              >
                <WhatsAppIcon />
              </IconButton>
            )}
          </Box>

          {/* Additional Info */}
          <Box
            sx={{
              px: { xs: 1, md: 0 },
              display: "flex",
              alignItems: "center",
              mt: 1,
            }}
          >
            <Typography variant="body2" sx={{ color: "gray" }}>
              Responds in 35 Mins · 58 people recently enquired
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <EnquiryFormDialog
        open={open}
        handleClose={handleClose}
        business={business}
      />
      {/* <OTPVerificationDialog open={open} handleClose={handleClose} />
       */}
      {/* <MobileNumberLoginDialog open={open} handleClose={handleClose} /> */}
    </>
  );
};

export default BusinessCard;

import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Box, List, ListItem } from "@mui/material";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
} from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <Container maxWidth="md" sx={{ py: 5 }}>
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" gutterBottom>
        Last Updated on: December 23, 2024
      </Typography>

      {/* Section 1 */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            1. General
          </Typography>
          <Typography variant="body1" paragraph>
            This Privacy Policy addresses your rights regarding the collection, use, and
            protection of your information when you register and use the website or mobile
            application ("Site") operated by Janata Rides (Smart Work Solutions) ("We",
            "Us", "Our"). By using our services, you agree to the terms outlined in this Privacy
            Policy. If you disagree, please do not use our services.
          </Typography>
          <Typography variant="body1" paragraph>
            1.1. We value your trust and maintain the highest standards for secure transactions
            and the privacy of your information.
          </Typography>
          <Typography variant="body1" paragraph>
            1.2. By accessing or using our Site, you consent to the collection, use, disclosure,
            and sharing of your information as per this Privacy Policy.
          </Typography>
          <Typography variant="body1" paragraph>
            1.3. If you do not want us to process your information, you may choose not to register
            or terminate your account with us.
          </Typography>
        </CardContent>
      </Card>

      {/* Section 2 */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            2. Information Collected
          </Typography>
          <Typography variant="body1" paragraph>
            2.1. Personal Information: As part of account creation and service usage, we may
            collect information such as your full name, email address, phone number, date of
            birth, and proof of identity or address.
          </Typography>
          <Typography variant="body1" paragraph>
            2.2. Additional Information: We collect usage data, including IP addresses,
            browser type, operating system, and session details.
          </Typography>
          <Typography variant="body1" paragraph>
            2.3. Cookies: Cookies help enhance your experience on our Site. You can disable
            them, but some features may not work properly.
          </Typography>
        </CardContent>
      </Card>

      {/* Section 3 */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            3. Use of Information
          </Typography>
          <Typography variant="body1" paragraph>
            Your information is used to operate and administer your account, provide and improve
            services, ensure compliance with laws, and prevent fraud.
          </Typography>
        </CardContent>
      </Card>

      {/* Section 4 */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            4. Retention of Data
          </Typography>
          <Typography variant="body1" paragraph>
            Upon account termination, we destroy your data except where required to comply with
            legal obligations. Please report phishing attempts to support@jantaridesmoney.com.
          </Typography>
        </CardContent>
      </Card>

      {/* Section 5 */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            5. Disclosure of Information
          </Typography>
          <Typography variant="body1" paragraph>
            We may disclose your information to regulatory authorities, law enforcement, or
            third-party service providers as legally required.
          </Typography>
        </CardContent>
      </Card>

      {/* Section 6 */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            6. Security
          </Typography>
          <Typography variant="body1" paragraph>
            We strive to protect your personal information from unauthorized access or
            misuse. In case of a data breach, affected users will be notified.
          </Typography>
        </CardContent>
      </Card>

      {/* Section 11 */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            11. Grievances
          </Typography>
          <Typography variant="body1" paragraph>
            For grievances, contact our Grievance Officer:
          </Typography>
          <Typography variant="body1">
            **Ms. Praveen Choudhary**  
            Email: jantarides@gmail.com  
            Phone: 7291003600
          </Typography>
        </CardContent>
      </Card>
    </Container>
      {/* <Box sx={{ padding: 8, maxWidth: "800px", margin: "0 auto" }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Privacy Policy
        </Typography>

        <Typography variant="body1" gutterBottom>
          <strong>Effective Date:</strong> 20/10/2022
        </Typography>

        <Typography variant="body1" paragraph>
          At Jantarides, we respect your privacy and are committed to protecting
          the personal information you share with us. This Privacy Policy
          explains how we collect, use, and safeguard your information when you
          use our website and mobile application (referred to collectively as
          the "Services"). By using our Services, you agree to the collection
          and use of information in accordance with this Privacy Policy.
        </Typography>

        <Typography variant="h5" color="#26376F" gutterBottom>
          1. Information We Collect
        </Typography>

        <Typography variant="body1" paragraph>
          We collect various types of information to provide and improve our
          Services, including:
        </Typography>

        <Typography variant="h6" gutterBottom>
          a. Personal Information
        </Typography>
        <Typography variant="body1" paragraph>
          When you register for our Services or book a service appointment, we
          may collect personal information such as:
        </Typography>

        <List sx={{ paddingLeft: 4 }}>
          <ListItem>Name</ListItem>
          <ListItem>Email address</ListItem>
          <ListItem>Phone number</ListItem>
          <ListItem>Vehicle details (e.g., make, model, year)</ListItem>
          <ListItem>Payment information (if applicable)</ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          b. Location Data
        </Typography>
        <Typography variant="body1" paragraph>
          In order to provide our services, which include car servicing and car
          cleaning at your doorstep, we collect and use your device’s location
          data. This data is necessary to:
        </Typography>

        <List sx={{ paddingLeft: 4 }}>
          <ListItem>
            Identify your current location for service delivery
          </ListItem>
          <ListItem>Estimate service times based on distance</ListItem>
          <ListItem>Assign service providers to your location</ListItem>
        </List>

        <Typography variant="body1" paragraph>
          You can enable or disable location services at any time through your
          device settings. However, disabling location access may impact our
          ability to provide doorstep services.
        </Typography>

        <Typography variant="h6" gutterBottom>
          c. Log Data
        </Typography>
        <Typography variant="body1" paragraph>
          We collect log information automatically when you interact with our
          website or app, such as:
        </Typography>

        <List sx={{ paddingLeft: 4 }}>
          <ListItem>IP address</ListItem>
          <ListItem>Browser type</ListItem>
          <ListItem>Device information</ListItem>
          <ListItem>Pages visited</ListItem>
          <ListItem>Time and date of visit</ListItem>
          <ListItem>Diagnostic data</ListItem>
        </List>

        <Typography variant="h5" color="#26376F" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use the information we collect for the following purposes:
        </Typography>

        <List sx={{ paddingLeft: 4 }}>
          <ListItem>To provide, operate, and maintain our Services</ListItem>
          <ListItem>
            To process your bookings and communicate service updates
          </ListItem>
          <ListItem>To personalize and improve your user experience</ListItem>
          <ListItem>To provide customer support</ListItem>
          <ListItem>To comply with legal obligations</ListItem>
        </List>

        <Typography variant="h5" color="#26376F" gutterBottom>
          3. Sharing Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We may share your personal information with third parties in the
          following situations:
        </Typography>

        <List sx={{ paddingLeft: 4 }}>
          <ListItem>
            <strong>Service Providers:</strong> We may share your information
            with third-party service providers who help us operate our business
            and provide the Services (e.g., payment processors, technicians, and
            customer service providers).
          </ListItem>
          <ListItem>
            <strong>Legal Requirements:</strong> We may disclose your
            information if required by law or to protect the rights, safety, or
            property of Jantarides, our users, or others.
          </ListItem>
          <ListItem>
            <strong>Business Transfers:</strong> In the event of a merger,
            acquisition, or asset sale, your information may be transferred as
            part of the transaction.
          </ListItem>
        </List>

        <Typography variant="h5" color="#26376F" gutterBottom>
          4. Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We take the security of your personal information seriously and
          implement industry-standard measures to protect it from unauthorized
          access, alteration, disclosure, or destruction. However, no method of
          transmission over the internet or electronic storage is 100% secure,
          and we cannot guarantee its absolute security.
        </Typography>

        <Typography variant="h5" color="#26376F" gutterBottom>
          5. Your Choices
        </Typography>
        <Typography variant="body1" paragraph>
          You have the following choices regarding your personal information:
        </Typography>

        <List sx={{ paddingLeft: 4 }}>
          <ListItem>
            <strong>Access and Update:</strong> You can access and update your
            personal information through your account settings or by contacting
            us.
          </ListItem>
          <ListItem>
            <strong>Location Data:</strong> You can enable or disable location
            services in your device settings. However, please note that certain
            features of our Services may not function properly if location
            access is disabled.
          </ListItem>
          <ListItem>
            <strong>Marketing Communications:</strong> You can opt-out of
            receiving marketing communications by following the unsubscribe
            instructions in our emails or contacting us directly.
          </ListItem>
        </List>

        <Typography variant="h5" color="#26376F" gutterBottom>
          6. Cookies and Tracking Technologies
        </Typography>
        <Typography variant="body1" paragraph>
          We use cookies and similar tracking technologies to collect
          information about your interactions with our website and app. You can
          manage cookie preferences through your browser settings, though
          disabling cookies may affect the functionality of our Services.
        </Typography>

        <Typography variant="h5" color="#26376F" gutterBottom>
          7. Children’s Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          Our Services are not directed to individuals under the age of 13, and
          we do not knowingly collect personal information from children. If we
          become aware that we have collected personal information from a child
          under 13, we will take steps to delete it.
        </Typography>

        <Typography variant="h5" color="#26376F" gutterBottom>
          8. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy from time to time. When we do, we
          will notify you by updating the effective date at the top of this
          policy. We encourage you to review this policy periodically for any
          changes.
        </Typography>

        <Typography variant="h5" color="#26376F" gutterBottom>
          9. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy, please contact us
          at:
        </Typography>

        <List sx={{ paddingLeft: 4 }}>
          <ListItem>
            <strong>Email:</strong> jantarides@gmail.com
          </ListItem>
        </List>
      </Box> */}
      <Footer />
    </>
  );
};

export default PrivacyPolicy;

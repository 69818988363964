import React from "react";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Header from "../components/Header";
import FAQ from "../components/Faq";
import Footer from "../components/Footer";
import { Container, Typography, Grid, Card, CardContent, Avatar } from "@mui/material";

const services = [
  {
    title: "Driver App Login",
    description:
      "Drivers need secure access to the app to view ride requests and manage their schedules.",
  },
  {
    title: "Real-Time Tracking",
    description:
      "Allows users to track the location of their booked vehicle in real-time.",
  },
  {
    title: "Real-Time Car Details Fetching",
    description:
      "Users can view real-time details of the vehicle assigned to their ride.",
  },
  {
    title: "Review Features",
    description: "Users can rate and review drivers after completing a ride.",
  },
  {
    title: "Referral Code",
    description:
      "Users can refer the app to others using a unique referral code, earning rewards.",
  },
  {
    title: "Penalty on Drink and Drive",
    description:
      "Penalize drivers caught under the influence of alcohol during duty.",
  },
  {
    title: "GST 12% on Each Ride",
    description:
      "Automatically calculate and apply GST on every completed ride fare.",
  },
  {
    title: "Sharing and Personal Booking",
    description:
      "Users can book rides for themselves or share rides with others.",
  },
  {
    title: "Vendor Can Add Multiple Vehicles",
    description:
      "Vendors (fleet owners) can add multiple vehicles to the platform.",
  },
  {
    title: "Commission for Vehicle Additions",
    description: "Reward the vendor who adds a vehicle when it gets a ride.",
  },
  {
    title: "Tree-Like Structure",
    description:
      "Organize data in a hierarchical tree structure for efficient management.",
  },
  {
    title: "Night Charges (5% After 12 AM)",
    description: "Apply surcharges for rides booked after midnight.",
  },
];

const AboutUs = () => {
  return (
    <>
      <Header />

      <Container maxWidth="md" sx={{ py: 5 }}>
      {/* About Us Section */}
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        About Us
      </Typography>
      <Typography variant="h5" fontWeight="bold" color="primary">
        Redefining Mobility for Billions
      </Typography>
      <Typography variant="body1" paragraph>
        Janata Rides is an upcoming India’s largest mobility platform and one of the world’s largest ride-hailing companies, serving 50+ cities across India.
        The Janata Rides app offers mobility solutions by connecting customers to drivers, onboarding a wide range of vehicles including bikes, auto-rickshaws,
        metered taxis, electric vehicles, and cabs—enabling convenience and transparency for millions of consumers and thousands of driver-partners.
      </Typography>
      <Typography variant="body1" paragraph>
        Janata Rides’s core mobility offering in India is supplemented by its electric-vehicle arm, <b>Janata Rides Electric</b>; upcoming India’s largest fleet
        management business, <b>Janata Rides Fleet Technologies</b>, and <b>Janata Rides Skilling</b>, which aims to enable millions of livelihood opportunities for India's youth.
        With its acquisition of <b>Ridlr</b>, India’s leading public transportation app, and investment in <b>Vogo</b>, a dockless scooter-sharing solution,
        Janata Rides is building mobility for the next billion Indians.
      </Typography>
      <Typography variant="body1" paragraph>
        Janata Rides also extends consumer offerings like micro-insurance and credit-led payments through <b>Janata Rides Financial Services</b>, as well as a range of
        owned electric vehicle franchise brands, creating India’s largest network of electric vehicles under its franchise business.
      </Typography>
      <Typography variant="body1" fontWeight="bold" paragraph>
        Janata Rides was founded in November 2024 by <b>Vishwakant Tiwari</b> with a mission to build mobility for a billion people.
      </Typography>

      {/* Key Stats Section */}
      <Grid container spacing={3} sx={{ my: 4 }}>
        <Grid item xs={12} sm={3}>
          <Card sx={{  height: 150, // Fixed height
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        p: 2,}}>
            <Typography variant="h6" fontWeight="bold">
              1 Lakhs+
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Rides served every year
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card sx={{  height: 150, // Fixed height
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        p: 2, }}>
            <Typography variant="h6" fontWeight="bold">
              50+
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Cities serviced
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card sx={{  height: 150, // Fixed height
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        p: 2, }}>
            <Typography variant="h6" fontWeight="bold">
              Thousands
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Empowered driver-partners
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card sx={{  height: 150, // Fixed height
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        p: 2, }}>
            <Typography variant="h6" fontWeight="bold">
              500+
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Employees working tirelessly
            </Typography>
          </Card>
        </Grid>
      </Grid>

      {/* Founder Section */}
      <Typography variant="h5" fontWeight="bold" gutterBottom>
        Founder & CEO
      </Typography>
      <Card sx={{ display: "flex", alignItems: "center", p: 3, mb: 4 }}>
        {/* <Avatar
          src="https://via.placeholder.com/150" // Replace with actual image
          alt="Vishwakant Tiwari"
          sx={{ width: 80, height: 80, mr: 3 }}
        /> */}
        <CardContent>
          <Typography variant="h6" fontWeight="bold">
            Vishwakant Tiwari
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Founder & CEO
          </Typography>
          <Typography variant="body1" paragraph>
            Vishwakant Tiwari is the Founder & CEO of Janata Rides, one of the world’s largest ride-sharing platforms and India’s most popular mobile app for transportation.
            With the mission of building mobility for a billion people, he co-founded Janata Rides in 2024 with Manish Kumar, a fellow alumnus from the Indian Institute of Technology in Mumbai.
          </Typography>
          <Typography variant="body1">
            Before Janata Rides, Vishwakant worked with Smart Work Solutions for five years, filing two business structures while running three businesses at a national growth level.
            True to his belief in on-demand consumer transportation, Vishwakant doesn’t own a car himself—and vows that he never will!
          </Typography>
        </CardContent>
      </Card>
    </Container>
      {/* <Box
        sx={{
          position: "relative",
          p: 5,
          textAlign: "center",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ maxWidth: "800px" }}>
          <Typography
            variant="h3"
            sx={{ fontWeight: "bold", mb: 3, lineHeight: 1.2 }}
          >
            About Janta Rides
          </Typography>
          <Typography variant="h6" sx={{ mb: 4 }}>
            Jant Rides is dedicated to providing the best ride experience across
            India. Whether you're looking for luxury, convenience, or a quick
            trip, we've got you covered! Our services span from city rides to
            long-distance travel, ensuring safety, comfort, and satisfaction
            every step of the way.
          </Typography>
          <Divider sx={{ mb: 4 }} />
          <Typography variant="h5" sx={{ mb: 3 }}>
            Our Key Features:
          </Typography>
          <Grid container spacing={3}>
            {services.map((service, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index + "service.title45" + service.title + index}
              >
                <Paper
                  sx={{
                    p: 1,
                    m: 1,
                    height: "100%",
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                    {service.title}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {service.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Divider sx={{ mt: 4, mb: 4, bgcolor: "#fff" }} />
          </Box>
      </Box> */}
      <FAQ />
      <Footer />
    </>
  );
};

export default AboutUs;
